<script setup>

const htmlContent = ref('')
const collapsed = ref(true)
const { t, locale } = useI18n()
const step = useStep()
const isLoading = ref(false)

const fetchHtmlContent = async () => {
try {
    isLoading.value = true;
    debugger
    const lang = locale.value.slice(0, 2)
    const response = await fetch(`/api/getWarrantyHtml?lang=${lang}`)
    const data = await response.json()
    if (data.html) {
    htmlContent.value = data.html
    } else if (data.error) {
    htmlContent.value = `<p>Error: ${data.error}</p>`
    incStep()
    }
} catch (error) {
    incStep()
}
finally {
    isLoading.value = false;
}
}

const toggleCollapse = () => {
collapsed.value = !collapsed.value
}

onMounted(() => {
fetchHtmlContent()
})

async function incStep() {
if (step.value.currentStep < step.value.maxSteps) {
    step.value.currentStep++
}
}
</script>

<template>
    <div>
      <div v-if="isLoading">Loading...</div>
      <div v-if="collapsed" v-html="htmlContent" class="overflow-hidden h-[210px]"></div>
      <div v-else v-html="htmlContent"></div>
      <button @click="toggleCollapse" class="underline scheppach">
        {{ collapsed ? t('show_more') : t('show_less') }}
      </button>
      <div class="button-container">
        <SButton @click="incStep" :disabled="isLoading">
          <SMdiIcon>check</SMdiIcon> {{$t("accept_warranty_conditions")}}
        </SButton>
      </div>
    </div>
  </template>

<style scoped>
.scheppach {
    color: var(--scheppach-primary-300);
    font-size: small;
    font-weight: bold;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>