
// @ts-nocheck
import locale__app_i18n_src_de_DE_js from "../i18n/src/de-DE.js";
import locale__app_i18n_src_en_US_js from "../i18n/src/en-US.js";
import locale__app_i18n_src_fr_FR_js from "../i18n/src/fr-FR.js";
import locale__app_i18n_src_sv_SE_js from "../i18n/src/sv-SE.js";
import locale__app_i18n_src_et_EE_js from "../i18n/src/et-EE.js";
import locale__app_i18n_src_it_IT_js from "../i18n/src/it-IT.js";
import locale__app_i18n_src_nl_NL_js from "../i18n/src/nl-NL.js";
import locale__app_i18n_src_es_ES_js from "../i18n/src/es-ES.js";
import locale__app_i18n_src_pt_PT_js from "../i18n/src/pt-PT.js";
import locale__app_i18n_src_cs_CZ_js from "../i18n/src/cs-CZ.js";
import locale__app_i18n_src_sk_SK_js from "../i18n/src/sk-SK.js";
import locale__app_i18n_src_hu_HU_js from "../i18n/src/hu-HU.js";
import locale__app_i18n_src_pl_PL_js from "../i18n/src/pl-PL.js";
import locale__app_i18n_src_hr_HR_js from "../i18n/src/hr-HR.js";
import locale__app_i18n_src_sl_SI_js from "../i18n/src/sl-SI.js";
import locale__app_i18n_src_lt_LT_js from "../i18n/src/lt-LT.js";
import locale__app_i18n_src_lv_LV_js from "../i18n/src/lv-LV.js";
import locale__app_i18n_src_fi_FI_js from "../i18n/src/fi-FI.js";
import locale__app_i18n_src_da_DK_js from "../i18n/src/da-DK.js";
import locale__app_i18n_src_no_NO_js from "../i18n/src/no-NO.js";
import locale__app_i18n_src_bg_BG_js from "../i18n/src/bg-BG.js";
import locale__app_i18n_src_el_GR_js from "../i18n/src/el-GR.js";
import locale__app_i18n_src_ro_RO_js from "../i18n/src/ro-RO.js";
import locale__app_i18n_src_rs_RS_js from "../i18n/src/rs-RS.js";
import locale__app_i18n_src_tr_TR_js from "../i18n/src/tr-TR.js";


export const localeCodes =  [
  "de-DE",
  "en-US",
  "fr-FR",
  "sv-SE",
  "et-EE",
  "it-IT",
  "nl-NL",
  "es-ES",
  "pt-PT",
  "cs-CZ",
  "sk-SK",
  "hu-HU",
  "pl-PL",
  "hr-HR",
  "sl-SI",
  "lt-LT",
  "lv-LV",
  "fi-FI",
  "da-DK",
  "no-NO",
  "bg-BG",
  "el-GR",
  "lb-LU",
  "ro-RO",
  "rs-RS",
  "tr-TR"
]

export const localeLoaders = {
  "de-DE": [{ key: "../i18n/src/de-DE.js", load: () => Promise.resolve(locale__app_i18n_src_de_DE_js), cache: true }],
  "en-US": [{ key: "../i18n/src/en-US.js", load: () => Promise.resolve(locale__app_i18n_src_en_US_js), cache: true }],
  "fr-FR": [{ key: "../i18n/src/fr-FR.js", load: () => Promise.resolve(locale__app_i18n_src_fr_FR_js), cache: true }],
  "sv-SE": [{ key: "../i18n/src/sv-SE.js", load: () => Promise.resolve(locale__app_i18n_src_sv_SE_js), cache: true }],
  "et-EE": [{ key: "../i18n/src/et-EE.js", load: () => Promise.resolve(locale__app_i18n_src_et_EE_js), cache: true }],
  "it-IT": [{ key: "../i18n/src/it-IT.js", load: () => Promise.resolve(locale__app_i18n_src_it_IT_js), cache: true }],
  "nl-NL": [{ key: "../i18n/src/nl-NL.js", load: () => Promise.resolve(locale__app_i18n_src_nl_NL_js), cache: true }],
  "es-ES": [{ key: "../i18n/src/es-ES.js", load: () => Promise.resolve(locale__app_i18n_src_es_ES_js), cache: true }],
  "pt-PT": [{ key: "../i18n/src/pt-PT.js", load: () => Promise.resolve(locale__app_i18n_src_pt_PT_js), cache: true }],
  "cs-CZ": [{ key: "../i18n/src/cs-CZ.js", load: () => Promise.resolve(locale__app_i18n_src_cs_CZ_js), cache: true }],
  "sk-SK": [{ key: "../i18n/src/sk-SK.js", load: () => Promise.resolve(locale__app_i18n_src_sk_SK_js), cache: true }],
  "hu-HU": [{ key: "../i18n/src/hu-HU.js", load: () => Promise.resolve(locale__app_i18n_src_hu_HU_js), cache: true }],
  "pl-PL": [{ key: "../i18n/src/pl-PL.js", load: () => Promise.resolve(locale__app_i18n_src_pl_PL_js), cache: true }],
  "hr-HR": [{ key: "../i18n/src/hr-HR.js", load: () => Promise.resolve(locale__app_i18n_src_hr_HR_js), cache: true }],
  "sl-SI": [{ key: "../i18n/src/sl-SI.js", load: () => Promise.resolve(locale__app_i18n_src_sl_SI_js), cache: true }],
  "lt-LT": [{ key: "../i18n/src/lt-LT.js", load: () => Promise.resolve(locale__app_i18n_src_lt_LT_js), cache: true }],
  "lv-LV": [{ key: "../i18n/src/lv-LV.js", load: () => Promise.resolve(locale__app_i18n_src_lv_LV_js), cache: true }],
  "fi-FI": [{ key: "../i18n/src/fi-FI.js", load: () => Promise.resolve(locale__app_i18n_src_fi_FI_js), cache: true }],
  "da-DK": [{ key: "../i18n/src/da-DK.js", load: () => Promise.resolve(locale__app_i18n_src_da_DK_js), cache: true }],
  "no-NO": [{ key: "../i18n/src/no-NO.js", load: () => Promise.resolve(locale__app_i18n_src_no_NO_js), cache: true }],
  "bg-BG": [{ key: "../i18n/src/bg-BG.js", load: () => Promise.resolve(locale__app_i18n_src_bg_BG_js), cache: true }],
  "el-GR": [{ key: "../i18n/src/el-GR.js", load: () => Promise.resolve(locale__app_i18n_src_el_GR_js), cache: true }],
  "lb-LU": [{ key: "../i18n/src/fr-FR.js", load: () => Promise.resolve(locale__app_i18n_src_fr_FR_js), cache: true }],
  "ro-RO": [{ key: "../i18n/src/ro-RO.js", load: () => Promise.resolve(locale__app_i18n_src_ro_RO_js), cache: true }],
  "rs-RS": [{ key: "../i18n/src/rs-RS.js", load: () => Promise.resolve(locale__app_i18n_src_rs_RS_js), cache: true }],
  "tr-TR": [{ key: "../i18n/src/tr-TR.js", load: () => Promise.resolve(locale__app_i18n_src_tr_TR_js), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de-DE",
      "language": "de-DE",
      "name": "Deutsch",
      "files": [
        "/app/i18n/src/de-DE.js"
      ]
    },
    {
      "code": "en-US",
      "language": "en-US",
      "name": "English",
      "files": [
        "/app/i18n/src/en-US.js"
      ]
    },
    {
      "code": "fr-FR",
      "language": "fr-FR",
      "name": "Français",
      "files": [
        "/app/i18n/src/fr-FR.js"
      ]
    },
    {
      "code": "sv-SE",
      "language": "sv-SE",
      "name": "Svenska",
      "files": [
        "/app/i18n/src/sv-SE.js"
      ]
    },
    {
      "code": "et-EE",
      "language": "et-EE",
      "name": "Eesti",
      "files": [
        "/app/i18n/src/et-EE.js"
      ]
    },
    {
      "code": "it-IT",
      "language": "it-IT",
      "name": "Italiano",
      "files": [
        "/app/i18n/src/it-IT.js"
      ]
    },
    {
      "code": "nl-NL",
      "language": "nl-NL",
      "name": "Nederlands",
      "files": [
        "/app/i18n/src/nl-NL.js"
      ]
    },
    {
      "code": "es-ES",
      "language": "es-ES",
      "name": "Español",
      "files": [
        "/app/i18n/src/es-ES.js"
      ]
    },
    {
      "code": "pt-PT",
      "language": "pt-PT",
      "name": "Português",
      "files": [
        "/app/i18n/src/pt-PT.js"
      ]
    },
    {
      "code": "cs-CZ",
      "language": "cs-CZ",
      "name": "Čeština",
      "files": [
        "/app/i18n/src/cs-CZ.js"
      ]
    },
    {
      "code": "sk-SK",
      "language": "sk-SK",
      "name": "Slovenčina",
      "files": [
        "/app/i18n/src/sk-SK.js"
      ]
    },
    {
      "code": "hu-HU",
      "language": "hu-HU",
      "name": "Magyar",
      "files": [
        "/app/i18n/src/hu-HU.js"
      ]
    },
    {
      "code": "pl-PL",
      "language": "pl-PL",
      "name": "Polski",
      "files": [
        "/app/i18n/src/pl-PL.js"
      ]
    },
    {
      "code": "hr-HR",
      "language": "hr-HR",
      "name": "Hrvatski",
      "files": [
        "/app/i18n/src/hr-HR.js"
      ]
    },
    {
      "code": "sl-SI",
      "language": "sl-SI",
      "name": "Slovenščina",
      "files": [
        "/app/i18n/src/sl-SI.js"
      ]
    },
    {
      "code": "lt-LT",
      "language": "lt-LT",
      "name": "Lietuvių",
      "files": [
        "/app/i18n/src/lt-LT.js"
      ]
    },
    {
      "code": "lv-LV",
      "language": "lv-LV",
      "name": "Latviešu",
      "files": [
        "/app/i18n/src/lv-LV.js"
      ]
    },
    {
      "code": "fi-FI",
      "language": "fi-FI",
      "name": "Suomi",
      "files": [
        "/app/i18n/src/fi-FI.js"
      ]
    },
    {
      "code": "da-DK",
      "language": "da-DK",
      "name": "Dansk",
      "files": [
        "/app/i18n/src/da-DK.js"
      ]
    },
    {
      "code": "no-NO",
      "language": "no-NO",
      "name": "Norsk",
      "files": [
        "/app/i18n/src/no-NO.js"
      ]
    },
    {
      "code": "bg-BG",
      "language": "bg-BG",
      "name": "Български",
      "files": [
        "/app/i18n/src/bg-BG.js"
      ]
    },
    {
      "code": "el-GR",
      "language": "el-GR",
      "name": "Ελληνικά",
      "files": [
        "/app/i18n/src/el-GR.js"
      ]
    },
    {
      "code": "lb-LU",
      "language": "lb-LU",
      "name": "Luxembourg",
      "files": [
        "/app/i18n/src/fr-FR.js"
      ]
    },
    {
      "code": "ro-RO",
      "language": "ro-RO",
      "name": "Română",
      "files": [
        "/app/i18n/src/ro-RO.js"
      ]
    },
    {
      "code": "rs-RS",
      "language": "rs-RS",
      "name": "Српски",
      "files": [
        "/app/i18n/src/rs-RS.js"
      ]
    },
    {
      "code": "tr-TR",
      "language": "tr-TR",
      "name": "Türkçe",
      "files": [
        "/app/i18n/src/tr-TR.js"
      ]
    }
  ],
  "defaultLocale": "de-DE",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "i18n/src/",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de-DE",
    "language": "de-DE",
    "name": "Deutsch",
    "files": [
      {
        "path": "/app/i18n/src/de-DE.js"
      }
    ]
  },
  {
    "code": "en-US",
    "language": "en-US",
    "name": "English",
    "files": [
      {
        "path": "/app/i18n/src/en-US.js"
      }
    ]
  },
  {
    "code": "fr-FR",
    "language": "fr-FR",
    "name": "Français",
    "files": [
      {
        "path": "/app/i18n/src/fr-FR.js"
      }
    ]
  },
  {
    "code": "sv-SE",
    "language": "sv-SE",
    "name": "Svenska",
    "files": [
      {
        "path": "/app/i18n/src/sv-SE.js"
      }
    ]
  },
  {
    "code": "et-EE",
    "language": "et-EE",
    "name": "Eesti",
    "files": [
      {
        "path": "/app/i18n/src/et-EE.js"
      }
    ]
  },
  {
    "code": "it-IT",
    "language": "it-IT",
    "name": "Italiano",
    "files": [
      {
        "path": "/app/i18n/src/it-IT.js"
      }
    ]
  },
  {
    "code": "nl-NL",
    "language": "nl-NL",
    "name": "Nederlands",
    "files": [
      {
        "path": "/app/i18n/src/nl-NL.js"
      }
    ]
  },
  {
    "code": "es-ES",
    "language": "es-ES",
    "name": "Español",
    "files": [
      {
        "path": "/app/i18n/src/es-ES.js"
      }
    ]
  },
  {
    "code": "pt-PT",
    "language": "pt-PT",
    "name": "Português",
    "files": [
      {
        "path": "/app/i18n/src/pt-PT.js"
      }
    ]
  },
  {
    "code": "cs-CZ",
    "language": "cs-CZ",
    "name": "Čeština",
    "files": [
      {
        "path": "/app/i18n/src/cs-CZ.js"
      }
    ]
  },
  {
    "code": "sk-SK",
    "language": "sk-SK",
    "name": "Slovenčina",
    "files": [
      {
        "path": "/app/i18n/src/sk-SK.js"
      }
    ]
  },
  {
    "code": "hu-HU",
    "language": "hu-HU",
    "name": "Magyar",
    "files": [
      {
        "path": "/app/i18n/src/hu-HU.js"
      }
    ]
  },
  {
    "code": "pl-PL",
    "language": "pl-PL",
    "name": "Polski",
    "files": [
      {
        "path": "/app/i18n/src/pl-PL.js"
      }
    ]
  },
  {
    "code": "hr-HR",
    "language": "hr-HR",
    "name": "Hrvatski",
    "files": [
      {
        "path": "/app/i18n/src/hr-HR.js"
      }
    ]
  },
  {
    "code": "sl-SI",
    "language": "sl-SI",
    "name": "Slovenščina",
    "files": [
      {
        "path": "/app/i18n/src/sl-SI.js"
      }
    ]
  },
  {
    "code": "lt-LT",
    "language": "lt-LT",
    "name": "Lietuvių",
    "files": [
      {
        "path": "/app/i18n/src/lt-LT.js"
      }
    ]
  },
  {
    "code": "lv-LV",
    "language": "lv-LV",
    "name": "Latviešu",
    "files": [
      {
        "path": "/app/i18n/src/lv-LV.js"
      }
    ]
  },
  {
    "code": "fi-FI",
    "language": "fi-FI",
    "name": "Suomi",
    "files": [
      {
        "path": "/app/i18n/src/fi-FI.js"
      }
    ]
  },
  {
    "code": "da-DK",
    "language": "da-DK",
    "name": "Dansk",
    "files": [
      {
        "path": "/app/i18n/src/da-DK.js"
      }
    ]
  },
  {
    "code": "no-NO",
    "language": "no-NO",
    "name": "Norsk",
    "files": [
      {
        "path": "/app/i18n/src/no-NO.js"
      }
    ]
  },
  {
    "code": "bg-BG",
    "language": "bg-BG",
    "name": "Български",
    "files": [
      {
        "path": "/app/i18n/src/bg-BG.js"
      }
    ]
  },
  {
    "code": "el-GR",
    "language": "el-GR",
    "name": "Ελληνικά",
    "files": [
      {
        "path": "/app/i18n/src/el-GR.js"
      }
    ]
  },
  {
    "code": "lb-LU",
    "language": "lb-LU",
    "name": "Luxembourg",
    "files": [
      {
        "path": "/app/i18n/src/fr-FR.js"
      }
    ]
  },
  {
    "code": "ro-RO",
    "language": "ro-RO",
    "name": "Română",
    "files": [
      {
        "path": "/app/i18n/src/ro-RO.js"
      }
    ]
  },
  {
    "code": "rs-RS",
    "language": "rs-RS",
    "name": "Српски",
    "files": [
      {
        "path": "/app/i18n/src/rs-RS.js"
      }
    ]
  },
  {
    "code": "tr-TR",
    "language": "tr-TR",
    "name": "Türkçe",
    "files": [
      {
        "path": "/app/i18n/src/tr-TR.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
