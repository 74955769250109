import { default as _91_46_46_46all_93FQm56YYVyGMeta } from "/app/pages/[...all].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "all___de-DE",
    path: "/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___en-US",
    path: "/en-US/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___fr-FR",
    path: "/fr-FR/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___sv-SE",
    path: "/sv-SE/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___et-EE",
    path: "/et-EE/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___it-IT",
    path: "/it-IT/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___nl-NL",
    path: "/nl-NL/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___es-ES",
    path: "/es-ES/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___pt-PT",
    path: "/pt-PT/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___cs-CZ",
    path: "/cs-CZ/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___sk-SK",
    path: "/sk-SK/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___hu-HU",
    path: "/hu-HU/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___pl-PL",
    path: "/pl-PL/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___hr-HR",
    path: "/hr-HR/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___sl-SI",
    path: "/sl-SI/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___lt-LT",
    path: "/lt-LT/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___lv-LV",
    path: "/lv-LV/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___fi-FI",
    path: "/fi-FI/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___da-DK",
    path: "/da-DK/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___no-NO",
    path: "/no-NO/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___bg-BG",
    path: "/bg-BG/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___el-GR",
    path: "/el-GR/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___lb-LU",
    path: "/lb-LU/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___ro-RO",
    path: "/ro-RO/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___rs-RS",
    path: "/rs-RS/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "all___tr-TR",
    path: "/tr-TR/:all(.*)*",
    component: () => import("/app/pages/[...all].vue")
  },
  {
    name: "index___de-DE",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en-US",
    path: "/en-US",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr-FR",
    path: "/fr-FR",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___sv-SE",
    path: "/sv-SE",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___et-EE",
    path: "/et-EE",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___it-IT",
    path: "/it-IT",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___nl-NL",
    path: "/nl-NL",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___es-ES",
    path: "/es-ES",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___pt-PT",
    path: "/pt-PT",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___cs-CZ",
    path: "/cs-CZ",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___sk-SK",
    path: "/sk-SK",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___hu-HU",
    path: "/hu-HU",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___pl-PL",
    path: "/pl-PL",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___hr-HR",
    path: "/hr-HR",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___sl-SI",
    path: "/sl-SI",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___lt-LT",
    path: "/lt-LT",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___lv-LV",
    path: "/lv-LV",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fi-FI",
    path: "/fi-FI",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___da-DK",
    path: "/da-DK",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___no-NO",
    path: "/no-NO",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___bg-BG",
    path: "/bg-BG",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___el-GR",
    path: "/el-GR",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___lb-LU",
    path: "/lb-LU",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___ro-RO",
    path: "/ro-RO",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___rs-RS",
    path: "/rs-RS",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___tr-TR",
    path: "/tr-TR",
    component: () => import("/app/pages/index.vue")
  }
]